import { Language } from '@yleisradio/areena-types';
import BrowserSupportAlert from 'components/BrowserSupportAlert';
import { Container } from 'components/Container';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { NotificationToaster } from 'components/Notifications';
import { Notification } from 'components/Notifications/Notification';
import { SkipLink } from 'components/SkipLink';
import { YleAnalytics } from 'components/YleAnalytics';
import { YleTunnus } from 'components/YleTunnus';
import { AnalyticsAvailabilityProvider } from 'contexts/AnalyticsAvailabilityContext';
import { CookieConsentContextProvider } from 'contexts/CookieConsentContext';
import { TunnusContextProvider } from 'contexts/TunnusContext';
import { useTranslation } from 'hooks/useTranslation';
import { IncomingMessage } from 'http';
import type {
  AppContext,
  AppInitialProps,
  AppProps as NextAppProps,
} from 'next/app';
import NextApp from 'next/app';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import Script from 'next/script';
import React, { useEffect } from 'react';
import * as ApmRum from 'services/apm-rum';
import { fetchBrowseMenuContentData } from 'services/areena-api/fetchers';
import { chatScriptUrl } from 'services/properties/frontend';
import 'styles/global.scss';
import { SWRConfig } from 'swr';
import { globalSWRConfig } from 'utils/fetch';
import { isAuthenticated } from 'utils/login';
import { getCspDirectives } from 'utils/security/csp';
import { Navigation } from 'components/Navigation';
import { AreenaFooter } from 'components/AreenaFooter';
import { AreenaServiceProvider } from 'contexts/AreenaServiceContext';
import { BrowseMenuContent } from '@yleisradio/areena-types/domain/v2/browseMenuContent';
import { ProgressCacheInvalidatorProvider } from 'contexts/ProgressCacheInvalidatorContext';
import { PlayerStateProvider } from 'contexts/PlayerStateContext';
import { LocationContextProvider } from 'contexts/LocationContext';
import dynamic from 'next/dynamic';
import { EscHandlerProvider } from 'contexts/EscHandlerContext/EscHandlerContext';
import { BrazeSdkProvider } from 'contexts/BrazeSdkContext';
import { SnowplowProvider } from 'contexts/SnowplowAnalyticsContext';

ApmRum.init();

const cleanUpUnusedLocalStorageItem = (): void => {
  window.localStorage.removeItem('isPodcastsTooltipDismissed');
  window.localStorage.removeItem('guide/regional-channel/yle-radio-suomi');
  window.localStorage.removeItem('guide/regional-channel/yle-radio-vega');
};

type AppProps = {
  userIsAuthenticated: boolean | null;
  browseMenuContent: BrowseMenuContent | null;
};

type AppRequest = IncomingMessage & {
  cookies: NextApiRequestCookies;
};

const Player = dynamic(() => import('../components/Player'));

function App({
  pageProps,
  Component,
  userIsAuthenticated,
  browseMenuContent,
}: AppProps & NextAppProps): React.ReactElement {
  useEffect(cleanUpUnusedLocalStorageItem, []);

  const t = useTranslation();

  const errorFallback = (
    <Container>
      <Notification
        helpText={t('notificationHelpTextGeneric')}
        notification={{ uiMessage: t('genericError') }}
        notificationStyle="error"
      />
    </Container>
  );

  return (
    <ErrorBoundary fallback={errorFallback}>
      <div key={'browserSupport'}>
        <BrowserSupportAlert />
      </div>
      <SWRConfig value={globalSWRConfig}>
        <TunnusContextProvider isAuthenticated={userIsAuthenticated}>
          <AnalyticsAvailabilityProvider>
            <CookieConsentContextProvider>
              <BrazeSdkProvider>
                <SnowplowProvider>
                  <AreenaServiceProvider>
                    <SkipLink />
                    <YleAnalytics />
                    <YleTunnus />
                    <EscHandlerProvider>
                      {userIsAuthenticated !== null && (
                        <Navigation browseMenuContent={browseMenuContent} />
                      )}
                      <ProgressCacheInvalidatorProvider>
                        <LocationContextProvider>
                          <PlayerStateProvider>
                            <ErrorBoundary fallback={errorFallback}>
                              <Component {...pageProps} />
                              <AreenaFooter />
                              <Player />
                            </ErrorBoundary>
                          </PlayerStateProvider>
                        </LocationContextProvider>
                      </ProgressCacheInvalidatorProvider>
                      <NotificationToaster />
                    </EscHandlerProvider>
                  </AreenaServiceProvider>
                </SnowplowProvider>
              </BrazeSdkProvider>
            </CookieConsentContextProvider>
          </AnalyticsAvailabilityProvider>
        </TunnusContextProvider>
      </SWRConfig>
      <Script src={chatScriptUrl} strategy="lazyOnload" />
    </ErrorBoundary>
  );
}

App.getInitialProps = async (
  appContext: AppContext
): Promise<AppInitialProps & AppProps> => {
  const cookies = (appContext.ctx.req as AppRequest)?.cookies;
  const { locale } = appContext.router;

  const language: Language = locale === 'sv' ? 'sv' : 'fi';
  const userIsAuthenticated = cookies ? isAuthenticated(cookies) : null;

  const appProps = await NextApp.getInitialProps(appContext);

  const browseMenuContent = await fetchBrowseMenuContentData(language);

  appContext.ctx.res?.setHeader('Content-Security-Policy', getCspDirectives());

  appContext.ctx.res?.setHeader(
    'Referrer-Policy',
    'strict-origin-when-cross-origin'
  );

  appContext.ctx.res?.setHeader('Strict-Transport-Security', 'max-age=7776000');
  appContext.ctx.res?.setHeader('X-Content-Type-Options', 'nosniff');

  return {
    ...appProps,
    userIsAuthenticated,
    browseMenuContent,
  };
};

export default App;
